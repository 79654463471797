<template>
  <div class="row col-xl-12 mx-0 px-0">
    <!--start::TOPFORM-->
    <Topform
      ref="page-topform"
      :managementType="managementType"
      :status="null"
      pageType="page"
      :name="pageInfo.title"
      :errors="pageErrors"
      :submissionStates="submissionStates"
      parentPage="/project/pages/summary"
      :tabs="computedTabs"
      :additionalActions="computedActions"
      :validation="computedvalidation"
      :loadingComplete="loadingComplete"
      @clear_errors="clearErrors()"
      @asset_reservation_created="form.media.push($event)"
      @asset_reservation_deleted="form.media = form.media.filter(function(stim) { return stim !== $event })"
      @remove_project_asset="form.media_remove.push($event)"
      @submit="submit(false)"
      @save_and_section="submit(true)"
      @clear_existing_data="clearData"
    >
    </Topform>
    <!--end::TOPFORM-->
    <div class="col-xl-4">
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <b-spinner
            v-if="loadingComplete == false"
            variant="primary"
            label="Spinning"
            class="card-loader"
          >
          </b-spinner>
          <div
            v-if="loadingComplete && !error.active"
          >
            <h6 class="mb-2">{{ $t('PAGES.MANAGE.HELPER_SEGMENT_TITLE') }}</h6>
            <p class="mb-10">{{ $t('PAGES.MANAGE.HELPER_SEGMENT_TEXT') }}</p>
            <div class="row">
              <AdvancedSegmentSelect
                id="input-system-project-role"
                class="px-5 mb-5"
                :submissionStates="submissionStates"
                :options="projectSegmentations"
                :initialSelection="initialSelection"
                children_key="project_segments"
                label_key="name"
                @children_changed="form.segments = $event"
                @clear_segments="form.segments = []"
              >
              </AdvancedSegmentSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, requiredIf, maxLength, url } from "vuelidate/lib/validators";
import AdvancedSegmentSelect from "@/view/components/form-inputs/AdvancedSegmentSelect";
import PageLoader from "@/core/classes/PageLoader.js";
import Toast from "@/core/classes/Toast.js";

//Vuex Imports
import {
  POPULATE_PAGE_INFO,
  CREATE_PAGE,
  UPDATE_PAGE,
  CLEAR_PAGE_ERRORS,
  CLEAR_PAGE_INFO
} from "@/core/services/store/page/page.module";

import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";

//Topform Imports
import TopformConfig from "@/core/config/topform/ManagePage.js";

export default {
  mixins: [validationMixin],

  name: "ManagePage",

  data() {
    return {
      pageLoader: new PageLoader(1),
      toast: new Toast(),
      submissionStates: {
        submitting: false
      },
      error: {
        active: false,
        message: ""
      },
      form: {
        published: 1,
        title: "",
        system_page_type_id: 1,
        section_type: 1,
        description: "",
        external_url: "",
        height: 250,
        segments: [],
        media: [],
        media_remove: []
      },
      sectionTypeOptions: [
        { id: 1, name:  `${this.$t("PAGES.MANAGE.SECTION_TYPE_1")}` },
        { id: 2, name:  `${this.$t("PAGES.MANAGE.SECTION_TYPE_2")}` },
        { id: 3, name:  `${this.$t("PAGES.MANAGE.SECTION_TYPE_3")}` },
      ],
      initialSelection: []
    }
  },

  validations: {
    form: {
      published: {
        required
      },
      title: {
        required,
        maxLength: maxLength(255)
      },
      description: {
        required: requiredIf(function() {
          return this.form.system_page_type_id != 4;
        })
      },
      system_page_type_id: {
        required
      },
      section_type: {
        required
      },
      external_url: {
        required: requiredIf(function() {
          return this.form.system_page_type_id == 3;
        }),
        url
      },
      height: {
        required: requiredIf(function() {
          return this.form.system_page_type_id == 4;
        })
      }
    }
  },

  components: {
    AdvancedSegmentSelect
  },

  mounted() {
    this.fetchProjectSegmentations();
    if (this.$route.params.page != undefined) {
      this.pageLoader.addRequiredComponent();
      this.fetchExistingPage();
    };
  },

  methods: {
    fetchProjectSegmentations: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectSegments: 'with',
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
    },
    fetchExistingPage: function() {
      let payload = {
        projectId: this.projectInfo.id,
        id: this.$route.params.page,
        params: {
          projectSegments: 'with',
          projectAssets: 'with',
          appendSignedUrls: 1
        }
      };
      this.$store.dispatch(POPULATE_PAGE_INFO, payload)
      .then(() => {
        this.form.published = this.pageInfo.published;
        this.form.title = this.pageInfo.title;
        this.form.system_page_type_id = this.pageInfo.system_page_type_id;
        
        if (this.pageInfo.system_page_type_id == 1 || this.pageInfo.system_page_type_id == 3) {
          this.form.section_type = this.pageInfo.section_type;
        }

        if (this.pageInfo.system_page_type_id != 4) {
          this.form.description = this.pageInfo.description;
        } else {
          this.form.height = this.pageInfo.height;
        }

        if (this.pageInfo.system_page_type_id == 3 || this.pageInfo.system_page_type_id == 4) {
          this.form.external_url = this.pageInfo.external_url;
        }
      
        this.pageInfo.project_segments.forEach((segment) => {
          this.initialSelection.push({ id:segment.id, parent:segment.project_segmentation_id });
        });

        this.pageLoader.setCompleteComponent();
      })
    },
    clearData: function() {
      this.$store.dispatch(CLEAR_PAGE_INFO)
    },
    submit: function(addSection) {
      this.clearErrors();

      this.submissionStates.submitting = true;

      if (!this.newPage) {
        this.submitUpdate(addSection);
      } else {
        this.submitPost(addSection);
      }
    },
    submitUpdate: function(addSection) {
      let form = this.form;

      if (form.system_page_type_id == 1) {
        delete form.external_url;
        delete form.height;
      } else if (form.system_page_type_id == 2) {
        delete form.external_url;
        delete form.height;
        delete form.section_type;
      } else if (form.system_page_type_id == 3) {
        delete form.height;
      } else if (form.system_page_type_id == 4) {
        delete form.description;
        delete form.section_type;
        delete form.media;
      }

      form.system_page_type_id = this.pageInfo.system_page_type_id;

      let payload = {
        projectId: this.projectInfo.id,
        id: this.$route.params.page,
        data: form
      }

      this.$store
        .dispatch(UPDATE_PAGE, payload)
        .then(() => {
          this.toast.notify(this, this.form.title + ' ' + `${this.$t("TOASTS.EDIT_PAGE.MESSAGE")}`, `${this.$t("TOASTS.EDIT_PAGE.TITLE")}`, 'success');
          if (addSection) {
            this.$router.push({ name: 'add_page_section', params: { page: this.pageInfo.id } })
          } else {
            this.$router.push({ name: 'pages_summary' });
          }
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    submitPost: function(addSection) {
      let form = this.form;

      if (form.system_page_type_id == 1) {
        delete form.external_url;
        delete form.height;
      } else if (form.system_page_type_id == 2) {
        delete form.external_url;
        delete form.height;
        delete form.section_type;
      } else if (form.system_page_type_id == 3) {
        delete form.height;
      } else if (form.system_page_type_id == 4) {
        delete form.description;
        delete form.section_type;
        delete form.media;
      }

      let payload = {
        projectId: this.projectInfo.id,
        data: form
      }

      this.$store
        .dispatch(CREATE_PAGE, payload)
        .then((res) => {
          this.toast.notify(this, this.form.title + ' ' + `${this.$t("TOASTS.ADD_PAGE.MESSAGE")}`, `${this.$t("TOASTS.ADD_PAGE.TITLE")}`, 'success');
          if (addSection) {
            this.$router.push({ name: 'add_page_section', params: { page: res.id } })
          } else {
            this.$router.push({ name: 'pages_summary' });
          }
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_PAGE_ERRORS);
    }
  },

  computed: {
    ...mapGetters([
      "systemPageTypes",
      "projectInfo",
      "pageInfo",
      "pageErrors",
      "projectSegmentations"
    ]),
    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },
    assetReservationData: function() {
      let data = {
        scope: 'page',
        data: {
          directory: 'media',
          projectId: this.projectInfo.id
        }
      }

      if (this.newPage) {
        data.data.new = 1;
      } else {
        data.data.pageId = this.pageInfo.id
      }

      return data;
    },
    calculatedCardTitle: function() {
      if (this.$route.params.page != undefined) {
        if (this.loadingComplete) {
          return `${this.$t("PAGES.MANAGE.EDIT")}` + ' - ' + this.pageInfo.title;
        } else {
          return `${this.$t("PAGES.MANAGE.EDIT")}`;
        }
      } else {
        return `${this.$t("PAGES.MANAGE.ADD")}`;
      }
    },
    newPage: function() {
      if (this.$route.params.page != undefined) {
        return false;
      } else {
        return true;
      }
    },
    managementType: function() {
      if (this.$route.params.page != undefined) {
        return "update";
      } else {
        return "post"
      }
    },
    computedActions: function() {
      return [
        {
          name: `${this.$t("GENERAL.BUTTONS.SAVE_AND_SECTION")}`,
          emit: 'save_and_section'
        }
      ]
    },
    computedvalidation: function() {
      return this.$v.form;
    },
    computedTabs: function() {
      return TopformConfig.topform(this);
    }
  }
};
</script>
