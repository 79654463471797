<template>
  <div>
    <div v-if="options.length == 0">
      <b-alert show variant="light">
        {{ $t('FORMS.INPUTS.NO_SEGMENTATIONS_MESSAGE') }}
      </b-alert>
    </div>
    <div v-else>
      <b-form-group
        :id="id + 'group'"
        :label="$t('GENERAL.ADVANCED_SEGMENT_SELECT.TITLE')"
        :label-for="id + '-all-select'"
        class="w-100 mb-3"
        label-class="font-weight-bolder"
      >
        <b-form-checkbox
          :id="id + '-all-select'"
          :name="id + '-all-select'"
          :value="1"
          unchecked-value="0"
          size="lg"
          :disabled="submissionStates.submitting === true"
          @change="applyAll($event)"
        >
          <p class="pt-1 mb-2">
            <span v-if="sequenced"> {{ $t('GENERAL.ADVANCED_SEGMENT_SELECT.ALL_PARTICIPANTS_SEQUENCED') }} </span>
            <span v-else> {{ $t('GENERAL.ADVANCED_SEGMENT_SELECT.ALL_PARTICIPANTS') }} </span>
          </p>
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        :id="id + 'parent-group'"
        :label="$t('GENERAL.ADVANCED_SEGMENT_SELECT.SUBTITLE')"
        :label-for="id + 'parent-group'"
        class="w-100 mb-0 ml-5"
        label-class="font-weight-bolder"
      >
        <template v-for="(parent, p) in options">
          <b-form-checkbox
            :id="id + '-parent-' + p"
            ref="parent"
            :name="id"
            :key="p"
            :value="parent.id"
            unchecked-value="0"
            size="lg"
            :disabled="submissionStates.submitting === true || all_select == 1"
            @change="propagateChildren($event, parent.id)"
          >
            <p class="pt-1 mb-2">{{ parent[computedLabelKey] }}</p>
          </b-form-checkbox>
          <template v-for="(child, c) in parent[children_key]">
            <b-form-checkbox
              :id="parent.id + '-child-' + child.id"
              :ref="parent.id"
              class="ml-7"
              :name="id"
              :key="p + '-' + c"
              :value="child.id"
              unchecked-value="0"
              size="lg"
              :disabled="submissionStates.submitting === true || all_select == 1"
              @change="updateChild($event, parent.id + '-child-' + child.id)"
            >
              <p class="pt-1 mb-2">{{ child[computedLabelKey] }}</p>
            </b-form-checkbox>
          </template>
        </template>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvancedSegmentSelect",

  props: {
    id: { type: String, required: true },
    error: { type: String, required: false},
    submissionStates: { type: Object, required: true },
    options: { type: Array, required: true },
    id_key: { type: String, required: false },
    children_key: { type: String, required: true },
    label_key: { type: String, required: false },
    initialSelection: { type: Array, required: true },
    sequenced: { type: Boolean, required: false }
  },

  data() {
    return {
      all_select: 0,
      parents: [],
      children: []
    }
  },

  mounted() {
    if (this.initialSelection.length == 0 && this.options.length > 0) {
      document.getElementById(this.id + '-all-select').click();
    } else if (this.options.length > 0) {
      this.initialSelection.forEach((segment) => {
        document.getElementById(segment.parent + '-child-' + segment.id).click();
      });
    }
  },

  methods: {
    applyAll: function(event) {
      if (event == 1) {
        var self = this;
        this.$refs.parent.forEach(function (parent) {
          if (self.parents.includes(parent.value)) {
            document.getElementById(parent.id).click();
          }
        });
        this.options.forEach(function (parent) {
          self.$refs[parent.id].forEach(function(child) {
            if (self.children.includes(child.value)) {
              document.getElementById(child.id).click();
            }
          })
        });
        this.children = [];
        this.parents = [];
        this.all_select = 1;
        this.$emit('clear_segments');
      } else {
        this.all_select = 0;
      }
    },
    updateChild: function(event, id) {
      let val = Number(document.getElementById(id).value);

      if (event != 0) {
        if (this.children.includes(val) == false) {
          this.children.push(val);
        }
      } else {
        if (this.children.includes(val)) {
          const index = this.children.indexOf(val);
          this.children.splice(index, 1);
        }
      }
    },
    propagateChildren: function($event, p) {
      var self = this;

      if ($event > 0) {
        this.parents.push(p);
      } else {
        const index = self.parents.indexOf(p);
        if (index > -1) {
          self.parents.splice(index, 1);
        }
      }

      this.$refs[p].forEach(function (item) {
        let val = item.value;

        if ($event > 0) {
          if (self.children.includes(val) == false) {
            document.getElementById(item.id).click();
          }
        } else {
          if (self.children.includes(val) == true) {
            document.getElementById(item.id).click();
          }
        }
      });
    }
  },

  watch: {
    children: {
      handler(val) {
        this.$emit('children_changed',val);
      },
      deep: true
    },
    initialSelection: {
      handler(val) {
        this.$emit('initial_selection_changed',val);
        
        if (this.initialSelection.length == 0) {
          if (this.all_select == 0) {
            document.getElementById(this.id + '-all-select').click();
          }
        } else {
          this.initialSelection.forEach((segment) => {
            document.getElementById(segment.parent + '-child-' + segment.id).click();
          });
        }
      },
      deep: true
    }
  },

  computed: {
    computedIdKey: function() {
      if (this.id_key != undefined) {
        return this.id_key;
      } else {
        return "id";
      }
    },
    computedLabelKey: function() {
      if (this.label_key != undefined) {
        return this.label_key;
      } else {
        return "label";
      }
    },
    computedSequenced: function() {
      if (this.sequenced != undefined) {
        return this.sequenced;
      } else {
        return false;
      }
    }
  }
};
</script>
