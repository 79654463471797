class ManagePage {

    //Suggests a key based on either a client or project name
    static topform(component) {
        return [
          {
            id: 1,
            sections: [
              { 
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericCheckbox",
                        properties: {
                          class: "col-xl-12 px-5 mb-5",
                          id: 'input-published',
                          label: `${component.$t("FORMS.INPUTS.PUBLISH")}`,
                          model: component.$v.form.published,
                          submissionStates: component.submissionStates
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-12 px-5 mb-10",
                          id: 'input-page-title',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.TITLE")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.TITLE")}`,
                          model: component.$v.form.title,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.TITLE")}`,
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputPageTypeRadioOption",
                        properties: {
                          id: 'input-page-type',
                          class: "col-xl-12 px-5 mb-0",
                          label: `${component.$t("FORMS.INPUTS.PAGE_TYPE")}` + ':',
                          error: `${component.$t("FORMS.ERROR.PAGE_TYPE")}`,
                          model: component.$v.form.system_page_type_id,
                          model2: component.$v.form.section_type,
                          options: component.systemPageTypes,
                          options2: component.sectionTypeOptions,
                          submissionStates: component.submissionStates,
                          newPage: component.newPage                        
                        }
                      }
                    ]
                  },
                  {
                    show: component.form.system_page_type_id == 3 || component.form.system_page_type_id == 4,
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-12 px-5 mb-10",
                          id: 'input-page-url',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.URL")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.URL")}`,
                          model: component.$v.form.external_url,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.URL")}`,
                          helper: `${component.$t("FORMS.HELPER.URL")}`,
                        }
                      }
                    ]
                  },
                  {
                    show: component.form.system_page_type_id == 4,
                    components: [
                      {
                        name: "InputGenericGroup",
                        properties: {
                          class: "col-xl-4 px-5 mb-10",
                          id: 'input-page-height',
                          alignLabel: "left",
                          showInline: false,
                          type: "number",
                          label: `${component.$t("FORMS.INPUTS.HEIGHT")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.HEIGHT")}`,
                          model: component.$v.form.height,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.HEIGHT")}`,
                          append: "px"
                        }
                      }
                    ]
                  },
                  {
                    show: component.form.system_page_type_id != 4,
                    components: [
                      {
                        name: "InputGenericRichText",
                        properties: {
                          class: "col-xl-12 px-5 mb-10",
                          id: 'input-page-description',
                          label: `${component.$t("FORMS.INPUTS.DESCRIPTION")}`,
                          model: component.$v.form.description,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.DESCRIPTION")}`
                        }
                      }
                    ]
                  },
                  {
                    show: component.form.system_page_type_id != 4,
                    components: [
                      {
                        name: "IntegratedDropzone",
                        on: {
                          asset_reservation_created: (value) => { component.$refs["page-topform"].$emit('asset_reservation_created', value) },
                          asset_reservation_deleted: (value) => { component.$refs["page-topform"].$emit('asset_reservation_deleted', value) },
                          remove_project_asset: (value) => { component.$refs["page-topform"].$emit('remove_project_asset', value) },
                        },
                        properties: {
                          class: "col-xl-12 px-5 mb-0",
                          assetReservation: component.assetReservationData,
                          idx: 1,
                          projectAssets: component.pageInfo.project_assets,
                          wholeWidth: true,
                          removable: true
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
    }
  
  }
  
export default ManagePage;